<template>
    <nav v-if="dataGridControl?.menuTabs" class="menu-tabs-nav" :class="[{'menu-tabs-nav-left': left}, navClass]">

<!--
        <button v-if="dataGridControl.menuTabs.sizerState === 'expanded'"
        -->
        <!--
        <button v-if="dataGridControl.menuTabs.sizerState === 'expanded' || (left ? dataGridControl.menuTabs.sizerState === 'collapsedLeft' : dataGridControl.menuTabs.sizerState === 'collapsedRight')"
            @click="dataGridControl.menuTabs.fullscreenMenu"
            class="btn btn-sm btn-link" :title="$t('Expand')">
            -->
            <!--
            <i class="bi bi-arrows-fullscreen"></i>
            -->
            <!--
            <i v-if="left" class="bi bi-chevron-double-right"></i>
            <i v-else class="bi bi-chevron-double-left"></i>
        </button>
        <button v-else-if="left ? dataGridControl.menuTabs.sizerState === 'collapsedRight' : dataGridControl.menuTabs.sizerState === 'collapsedLeft'"
            @click="dataGridControl.menuTabs.exitFullscreenMenu"
            class="btn btn-sm btn-link" :title="$t('Collapse')">
            -->
            <!--
            <i class="bi bi-fullscreen-exit"></i>
            -->
            <!--
            <i v-if="left" class="bi bi-chevron-left"></i>
            <i v-else class="bi bi-chevron-right"></i>
        </button>
        -->
        <!--
        <button v-if="dataGridControl.menuTabs.sizerState === 'expanded'"
        -->
        <button
            @click="dataGridControl.menuTabs.expandMenu"
            class="btn btn-sm btn-link menu-tab-button" :title="$t('Collapse')">
            <template v-if="!left">
                <i v-if="dataGridControl.menuTabs.sizerState === 'collapsedLeft'" class="bi bi-chevron-right"></i>
                <i v-else class="bi bi-chevron-double-left"></i>
            </template>
            <template v-else>
                <i v-if="dataGridControl.menuTabs.sizerState === 'collapsedRight'" class="bi bi-chevron-left"></i>
                <i v-else class="bi bi-chevron-double-right"></i>
            </template>
        </button>
        <button
            @click="dataGridControl.menuTabs.expandGrid"
            class="btn btn-sm btn-link menu-tab-button" :title="$t('Collapse')">
            <template v-if="left">
                <i v-if="dataGridControl.menuTabs.sizerState === 'collapsedLeft'" class="bi bi-chevron-right"></i>
                <i v-else class="bi bi-chevron-double-left"></i>
            </template>
            <template v-else>
                <i v-if="dataGridControl.menuTabs.sizerState === 'collapsedRight'" class="bi bi-chevron-left"></i>
                <i v-else class="bi bi-chevron-double-right"></i>
            </template>
        </button>

        <button v-if="dataGridControl.menuTabs.detailsTabEnabled" class="btn btn-link btn-sm nav-link menu-tab-button"
            :class="{ 'active': dataGridControl.menuTabs.activeTab === 'details' && dataGridControl.menuTabs.visible }"
            @click="dataGridControl.menuTabs.setActiveTab('details', skipCollapse);" :title="detailTabTitle || ''">
            <div class="mb-1">
                <i class="bi bi-card-text"></i>
            </div>
            <span class="menu-tab-text">{{ $t('Details') }}</span>
        </button>

        <button class="btn btn-link btn-sm nav-link menu-tab-button"
            :class="{ 'active': dataGridControl.menuTabs.activeTab === 'filters' && dataGridControl.menuTabs.visible }"
            @click="dataGridControl.menuTabs.setActiveTab('filters', skipCollapse);" :title="$t('Field Filters')">
            <div class="mb-1">
                <i class="bi bi-funnel"></i>
            </div>
            <span class="menu-tab-text">{{ $t('Filters') }}</span>
        </button>

        <button class="btn btn-link btn-sm nav-link menu-tab-button"
            :class="{ 'active': dataGridControl.menuTabs.activeTab === 'columns' && dataGridControl.menuTabs.visible }"
            @click="dataGridControl.menuTabs.setActiveTab('columns', skipCollapse);" :title="$t('Column Chooser')" aria-current="page">
            <div class="mb-1">
                <i class="bi bi-layout-three-columns"></i>
            </div>
            <span class="menu-tab-text">{{ $t('Columns') }}</span>
        </button>

        <button v-if="dataGridControl.dataObject" class="btn btn-link btn-sm nav-link menu-tab-button"
            :class="{ 'active': dataGridControl.menuTabs.activeTab === 'export' && dataGridControl.menuTabs.visible }"
            @click="dataGridControl.menuTabs.setActiveTab('export', skipCollapse);" :title="$t('Export')">
            <div>
                <i class="bi bi-database"></i>
            </div>
            <span class="menu-tab-text">{{ $t('Export') }}</span>
        </button>

        <button v-if="dataGridControl.menuTabs.layoutsTabEnabled" class="btn btn-link btn-sm nav-link menu-tab-button"
            :class="{ 'active': dataGridControl.menuTabs.activeTab === 'layouts' && dataGridControl.menuTabs.visible }"
            @click="dataGridControl.menuTabs.setActiveTab('layouts', skipCollapse);" :title="$t('Layouts')">
            <div class="mb-1">
                <i class="bi bi-grid-1x2"></i>
            </div>
            <span class="menu-tab-text">{{ $t('Layouts') }}</span>
        </button>

        <button v-if="dataGridControl.menuTabs.groupByFoldersTabEnabled" class="btn btn-link btn-sm nav-link menu-tab-button"
            :class="{ 'active': dataGridControl.menuTabs.activeTab === 'groupbyfolders' && dataGridControl.menuTabs.visible }"
            @click="dataGridControl.menuTabs.setActiveTab('groupbyfolders', skipCollapse);" :title="$t('Group By Folders')">
            <div class="mb-1">
                <i class="bi bi-folder"></i>
            </div>
            <span class="menu-tab-text">{{ $t('Group By Folders') }}</span>
        </button>

       <button
            @click="dataGridControl.menuTabs.switchSides"
            class="btn btn-sm btn-link menu-tab-button mt-auto" :title="$t(left ? 'Move grid panel to the right side' : 'Move grid panel to the left side')">
                <i v-if="left" class="bi bi-chevron-bar-right"></i>
                <i v-else class="bi bi-chevron-bar-left"></i>
        </button>
    </nav>
</template>

<script setup lang="ts">
import type DataGridControl from 'o365.controls.DataGrid.ts';
import type { Ref } from 'vue';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import { inject, computed } from 'vue';

const props = withDefaults(defineProps<{
    navClass?: string;
    skipCollapse?: boolean;
    left?: boolean;
    detailTabTitle?: string;
}>(), {
    navClass: 'nav flex-column bg-light-subtle border-start border-end',
    skipCollapse: false
});

const dataGridControl: Ref<DataGridControl> = inject(dataGridControlKey, null);

const detailsButtonTitle = computed(() => {
    if (props.left) {
        return dataGridControl.value?.menuTabs?.sizerState === 'collapsedLeft'
            ? ''
            : '';
    } else {
        return dataGridControl.value?.menuTabs?.sizerState === 'collapsedRight'
            ? ''
            : '';
    }
});
const gridButtonTitle = computed(() => {
    if (props.left) {
        return dataGridControl.value?.menuTabs?.sizerState === 'collapsedLeft'
            ? ''
            : '';
    } else {
        return dataGridControl.value?.menuTabs?.sizerState === 'collapsedRight'
            ? ''
            : '';
    }
});


</script>

<style scoped>
.menu-tabs-nav {
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    margin-right: 2px;
    min-width: calc(2rem + 2px);
    max-width: calc(2rem + 2px);
}

.menu-tabs-nav-left {
    margin-left: 2px;
    margin-right: unset;
}

.menu-tabs-nav::-webkit-scrollbar {
    display: none;
}

.menu-tab-button.active {
    background-color: rgba(var(--bs-primary-rgb), .1);
    border-right: 2px solid rgba(var(--bs-primary-rgb), .5);
    color: rgb(var(--bs-primary-rgb));
    font-weight: 500;
}
.menu-tabs-nav-left .menu-tab-button.active {
    border-left: 2px solid rgba(var(--bs-primary-rgb), .5);
    border-right: unset;
}

.menu-tab-button {
    border-radius: 0px;
    padding-left: 0.25rem;
    padding-right: 0rem;
    width: 2rem;
}

.menu-tab-button:hover {
    background-color: rgba(var(--bs-primary-rgb), .15);
}

.menu-tab-text {
    writing-mode: vertical-lr;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>